:root {
  --color-slate: #65676B;
  --color-grey: #CBC3BF;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--background-color);
  color: var(--secondary-color);
}

* {
  font-family: var(--primary-font-family);
}

p {
  margin: 0;
}

/*
  Utility classes
*/
.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
